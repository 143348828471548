@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

@font-face {
  font-family: 'lato-semibold';
  src: local('LatoSemibold'), url('./images/LatoSemibold.ttf') format('opentype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .main-button {
    @apply flex py-2 px-4 w-fit cursor-pointer items-center justify-center rounded-3xl font-lato font-medium text-primary transition-all duration-300 ease-in-out hover:rounded hover:bg-primaryVariant hover:text-white;
  }
}