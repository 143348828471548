$font-size-base-px: 16px;
@function rem-calc($px) {
  @return $px / $font-size-base-px * 1rem;
}

.platforms {
  max-width: 80rem;
  margin: 60px auto;
  background: linear-gradient(180deg, #6628eb 42.44%, #804eea 100%);

  border-radius: 30px;
  padding: 30px;
  .platforms-header {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: rem-calc(48px);
    line-height: 64px;
    color: white;
    padding: 30px 0px;
    text-align: center;
  }
  .platforms-container {
    .app-wrapper {
      display: flex;
      flex-direction: column;
      padding: 30px;
    }
  }

  &.mobile {
    max-width: 95%;
    .platforms-header {
      font-size: rem-calc(42px);
    }
    .platforms-container {
      .app-wrapper {
        padding: 10px;
      }
    }
  }
}
