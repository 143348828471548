$font-size-base-px: 16px;
@function rem-calc($px) {
  @return $px / $font-size-base-px * 1rem;
}

.playground {
  background: linear-gradient(304deg, #6628eb 42.44%, #804eea 100%);

  max-width: 80rem;
  background-color: white;
  border-radius: 30px;
  margin: 60px auto;
  font-family: "Manrope", sans-serif;
  border-left: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);

  .playground-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 60px;
    background: transparent;

    border-radius: 30px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    h1 {
      font-size: rem-calc(56px);
      font-weight: 600;
      line-height: 1.2;
      margin: 0;
      padding: 0;
      color: white;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: rem-calc(16px);
      line-height: 150%;
      color: white;
      text-align: justify;

      &.playground-text-title {
        font-weight: 600;
        font-size: rem-calc(20px);
      }
    }
  }

  .playground-form {
    padding: 30px;
  }

  &.mobile {
    max-width: 95%;
    h1 {
      font-size: rem-calc(42px);
      text-align: center;
    }
    p {
      font-size: rem-calc(16px);
      text-align: center;
    }
  }
}
