.custom-textarea-wrapper {
  display: flex;
  flex-direction: row;

  .custom-textarea {
    border: 1px solid #0000003b;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-right: none;
    border-top-right-radius: none;
    border-bottom-right-radius: none;
    padding: 10px;
    font-size: 16px;
    resize: none;
    height: 100px;
    width: 100%;
    outline: none;
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 1.8;
    color: #636f7f;
    min-height: 9rem;

    &:focus {
      border: 2px solid #642ae5;
    }
  }

  .custom-textarea-buttons {
    display: flex;
    flex-direction: column;
    border: 1px solid #0000003b;
    justify-content: space-evenly;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}
