.image-text-content {
  display: flex;
  flex-direction: row;
  font-family: "Manrope", sans-serif;
  gap: 5rem;

  .image-text-content__image {
    width: 20rem;
    height: 20rem;
  }

  .image-text-content__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 1.2;
      margin-bottom: 36px;
      color: #070538;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.8;
      color: #636f7f;
    }
  }

  &.mobile {
    flex-direction: column;
    gap: 2rem;
    .image-text-content__image {
      width: 100%;
      height: 100%;
      order: 1 !important;
    }
    .image-text-content__text {
      h2 {
        font-size: 32px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}
