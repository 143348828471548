.tutorial-container {
  pointer-events: none !important;
  user-select: none !important;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  min-width: 80%;
  position: relative;
  margin-top: -55px;
  z-index: 6;
  border: 1px solid #e6e6e6;

  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);

  .tutorial-user {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
    .user-icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #804eea;
      display: flex;
      color: white;
      justify-content: center;
      align-items: center;
    }
    .user-name {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .text {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 0%;
      height: 100%;
      background-color: #b4d5ff;
      opacity: 0.7;
      animation: highlighting 10s ease-in-out;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-play-state: running;
    }

    .context-menu-image {
      position: absolute;
      top: 10px;
      right: -200px;
      width: 200px;
      height: 150px;
      z-index: 999;
      background: url("../../images/context-menu.png");
      cursor: pointer;
      background-size: contain !important;
      animation: contextMenu 10s ease-in-out;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-play-state: running;
    }
  }

  .text-select-icon {
    position: absolute;
    padding: 4px;
    left: 0;
    animation: moveTextSelectCursor 10s ease-in-out;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-play-state: running;
  }

  .text-select-buttons-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    position: relative;
    .button-wrapper {
      display: flex;
      flex-direction: row;
      gap: 5px;

      .generate-button {
        background-color: #804eea;
        padding: 5px;
        border-radius: 5px;
        min-width: 24%;
        height: 40px;
        color: white;
        font-size: 14px;
        position: relative;
        animation: generateButtonClick 10s ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-play-state: running;
      }
    }
    .extra-information {
      padding: 5px;
      font-size: 15px;
      background-color: #f1f1f1;
      color: #333;
      border-radius: 4px;
    }

    .generated-text-container {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      z-index: 999;
      background-color: #ffffff;
      border-radius: 4px;
      padding: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      color: #000000;
      line-height: 1.2;
      display: flex;
      gap: 10px;
      align-items: center;
      transition: all 0.8s ease-in-out;
      transform: translate(0, 0);
      word-break: break-word;
      animation: showGeneratedText 10s ease-in-out;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-play-state: running;
      .generated-text-paragraph {
        overflow-y: hidden;
        max-height: 95%;
        font-size: 12px;
      }
      .copy-button {
        background-color: #804eea;
        color: #ffffff;
        padding: 8px;
        font-size: 15px;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        height: 100%;

        .copy-icon {
          width: 20px;
          height: 20px;
          fill: #f1f1f1;
        }
      }
    }
    animation: showGenerateButton 10s ease-in-out;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-play-state: running;
  }

  .selector {
    background: #f1f1f1;
    color: #333;
    border-radius: 4px;
    padding: 10px;
    min-width: 36.5%;
    height: 33px;
    font-size: 14px;
    border: none;
    outline: none;
    height: 40px;
  }

  .pointer-icon {
    position: absolute;
    z-index: 10000;
    padding-top: 4px;
    animation: movePointer 10s ease-in-out;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }

  .reply-input-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 10px;
    .reply-input {
      min-width: 80%;
      max-width: 18rem;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      padding: 10px;
      font-size: 14px;
      line-height: 1.2;
      outline: none;
      color: #f4f6f8;
      background: #f4f6f8;
      animation: clickAndInsertText 10s ease-in-out;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
    }
    .reply-button {
      color: #804eea;
      padding: 10px;
      min-width: 20%;
      font-size: 32px;
      border-radius: 5px;
      animation: replyButtonClick 10s ease-in-out;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
    }
  }

  .input-focus-container {
    visibility: hidden;
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 10px;
    animation: showPasteButton 10s ease-in-out;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-play-state: running;

    .paste-button {
      background-color: #804eea;
      min-width: 15%;
      font-size: 14px;
      height: 40px;
      border-radius: 5px;
      color: white;
      animation: pasteButtonClick 10s ease-in-out;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-play-state: running;
    }
    .template-button {
      background-color: #e6e6e6;
      min-width: 20%;
      font-size: 14px;
      border-radius: 4px;
      border: none;
    }
    .add-to-memory-button {
      min-width: 20%;
      background-color: #e6e6e6;
      font-size: 14px;
      border-radius: 4px;
      border: none;
    }
  }

  @keyframes movePointer {
    0% {
      visibility: hidden;
      transform: translate(0, 0);
    }
    10% {
      visibility: hidden;
    }
    13% {
      transform: translate(10px, 10px);
    }
    20%,
    22% {
      transform: translate(20px, 90px);
    }
    34% {
      transform: translate(-120px, 50px);
    }
    40% {
      transform: translate(-120px, 50px);
    }
    55% {
      transform: translate(-120px, 140px);
    }
    70% {
      transform: translate(-140px, 230px);
    }
    85% {
      transform: translate(240px, 170px);
    }
    100% {
      transform: translate(-180px, 0);
    }
  }

  @keyframes showGenerateButton {
    0%,
    25% {
      visibility: hidden;
    }
    49% {
      visibility: visible;
    }
    50%,
    100% {
      visibility: hidden;
    }
  }

  @keyframes showPasteButton {
    0%,
    57% {
      visibility: hidden;
    }
    58%,
    85% {
      visibility: visible;
    }
    86%,
    100% {
      visibility: hidden;
    }
  }

  @keyframes spin {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes highlighting {
    10%,
    50% {
      width: 100%;
    }
    51%,
    100% {
      visibility: hidden;
    }
  }
  @keyframes inputFocus {
    0%,
    10% {
      visibility: hidden;
    }
    50% {
      visibility: visible;
    }
    51%,
    100% {
      visibility: hidden;
    }
  }
  @keyframes moveTextSelectCursor {
    10%,
    100% {
      left: 100%;
      visibility: hidden;
    }
  }

  @keyframes showGeneratedText {
    0%,
    40% {
      visibility: hidden;
      width: 0px;
      height: 0px;
    }
    44%,
    54% {
      visibility: visible;
      width: 100%;
      height: 80px;
      transform: translate(1, 1);
    }
    54%,
    100% {
      visibility: hidden;
    }
  }
  @keyframes clickAndInsertText {
    0%,
    56% {
      border: 1px solid #e6e6e6;
    }
    57% {
      border: 2px solid blue;
    }
    60%,
    74% {
      border: 1px solid #e6e6e6;
      color: #f4f6f8;
      border: 2px solid blue;
    }
    75%,
    85% {
      color: #000000;
      border: 2px solid blue;
    }
    86%,
    100% {
      color: #f4f6f8;
      border: 1px solid #e6e6e6;
    }
  }

  @keyframes movePointerMobile {
    0% {
      visibility: hidden;
      transform: translate(0, 0);
    }
    10% {
      visibility: hidden;
    }
    13% {
      transform: translate(10px, 10px);
    }
    20%,
    22% {
      transform: translate(-50px, 110px);
    }
    34% {
      transform: translate(-130px, 50px);
    }
    40% {
      transform: translate(-130px, 50px);
    }
    55% {
      transform: translate(-120px, 140px);
    }
    70% {
      transform: translate(-150px, 290px);
    }
    85% {
      transform: translate(60px, 190px);
    }
    100% {
      transform: translate(-180px, 0);
    }
  }

  @keyframes contextMenu {
    0%,
    12% {
      background: url("../../images/context-menu.png");
      visibility: hidden;
    }
    13%,
    19% {
      visibility: visible;
      background: url("../../images/context-menu.png");
    }
    20% {
      visibility: visible;
      background: url("../../images/context-menu-hovered.png");
    }
    22% {
      visibility: visible;
      background: url("../../images/context-menu-hovered.png");
    }
    23%,
    100% {
      visibility: hidden;
    }
  }

  @keyframes generateButtonClick {
    0%,
    34% {
      border: none;
    }
    35% {
      border: 2px solid #e6e6e6;
    }
    36% {
      border: 2px solid #e6e6e6;
    }
    37%,
    100% {
      border: none;
    }
  }

  @keyframes pasteButtonClick {
    0%,
    69% {
      border: none;
    }
    70% {
      border: 2px solid #e6e6e6;
    }
    71% {
      border: 2px solid #e6e6e6;
    }
    72%,
    100% {
      border: none;
    }
  }

  @keyframes replyButtonClick {
    0%,
    79% {
      color: #804eea;
    }
    85% {
      color: #642ae5;
    }
    86% {
      color: #642ae5;
    }
    87%,
    100% {
      color: #804eea;
    }
  }

  &.mobile {
    margin-top: 0px;
    .pointer-icon {
      animation: movePointerMobile 10s ease-in-out;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
    }

    .context-menu-image {
      top: 25px;
      right: -40px;
    }

    .text-select-buttons-container {
      .button-wrapper {
        .generate-button {
          font-size: 12px;
        }
      }
    }
    .selector {
      font-size: 12px;
    }
    .paste-button {
      font-size: 12px;
      padding: 3px 10px;
    }
    .template-button {
      font-size: 11px;
      padding: 3px 10px;
    }
    .add-to-memory-button {
      font-size: 11px;
      padding: 3px 10px;
    }

    .reply-button {
      font-size: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
