$font-size-base-px: 16px;
@function rem-calc($px) {
  @return $px / $font-size-base-px * 1rem;
}

.benefits-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 9rem;
  font-family: "Manrope", sans-serif;

  .benefits-content {
    max-width: 80rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;

    .benefits-title {
      font-style: normal;
      font-weight: 600;
      font-size: rem-calc(56px);
      line-height: 1.2;

      text-align: center;
      color: #070538;
    }

    .highlight {
      color: #642ae5;
    }

    .benefits-description {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.8;
      color: #636f7f;
      margin-bottom: 2rem;
      text-align: center;
    }
  }

  &.mobile {
    padding-top: 100px;
    padding: 2rem 2rem;
    .benefits-content {
      max-width: 100%;
      .benefits-title {
        font-size: rem-calc(38px);
      }
      .benefits-description {
        font-size: 16px;
        text-align: center;
        padding: 0 2rem;
      }
    }
  }
}
