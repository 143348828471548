body {
  background-color: #faf8ff;

  padding: 0 1rem;

  @media (max-width: 600) {
    //remove scrollbar
    overflow: hidden;
  }
}
