.fade-in {
  animation: fade-in 4s ease-out forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
    top: 0;
  }
  to {
    opacity: 1;
    top: 100%;
  }
}
