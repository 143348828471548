$font-size-base-px: 16px;
@function rem-calc($px) {
  @return $px / $font-size-base-px * 1rem;
}

.feedback-button {
  position: fixed !important;
  bottom: 10px;
  right: 10px;
  border-radius: 50%;
  border: none;
  padding: 20px;
  color: white;
  background-color: white !important;
  font-size: rem-calc(24px);
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  border: 1px solid #0000003b;
  &:hover {
    cursor: pointer;
  }
}

.feedback-form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  font-family: "Manrope", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 400px;

  .feedback-option {
    display: flex;
    background-color: transparent;
    align-items: center;
    border: none;
    gap: 40px;
    margin-bottom: 20px;
    min-width: 100%;
    padding: 20px;
    svg {
      font-size: rem-calc(40px);
    }
    .feedback-option-content {
      display: flex;
      flex-direction: column;
      gap: 5px;
      h1 {
        font-size: rem-calc(18px);
        font-weight: 600;
        line-height: 1.2;
        margin: 0;
        padding: 0;
        color: #3e3e3e;
      }
      p {
        font-size: rem-calc(14px);
        font-weight: 400;
        line-height: 1.2;
        margin: 0;
        padding: 0;
        color: #8a96a6;
      }
    }

    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }

  .feedback-form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .feedback-form__textarea {
      width: 100%;
      height: 100px;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      padding: 10px;
      font-size: 16px;
      resize: none;
      border: 1px solid #0000003b;
      font-style: normal;
      font-weight: 500;
      line-height: 1.8;
      color: #636f7f;
      min-height: 9rem;

      &:focus {
        border: 2px solid #642ae5 !important;
      }

      &:focus-visible {
        outline: none;
      }
    }
    .feedback-form__button {
      width: 100%;
      height: 50px;
      background: #642ae5;
      border-radius: 5px;
      border: none;
      color: white;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        background: #4e1fc2;
      }
    }

    .feedback-form__error {
      color: red;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
      margin: 0;
      padding: 0;
    }

    .feedback-form__success {
      color: green;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
      margin: 0;
      padding: 0;
    }
  }
}
