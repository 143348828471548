.welcome-page {
    width: 650px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.how-does-it-work {
    font-size: 36px;
    margin-bottom: 40px;
}

.highlight-and-generate-text {
    display: inline-block;
    font-weight: bold;
}

.review-select {
    display: inline-block;
    font-size: 12px;
}

.review-select-text {
    display: inline-block;
    font-size: 12px;
}

.selected-review-box {
    width: 100%;
    height: 50px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.click-input-text {
    font-size: 16px;
    font-weight: bold;
}

.instructions, .example-review, .click-input-text, .selected-review {
    margin-top: 20px;
}

.input-text {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 2px solid lightgray;
    border-radius: 4px;
}

.input-text-container {
    width: 100%;
}