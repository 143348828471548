.generate-reply-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  border-radius: 25px;
  padding: 20px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);

  .generate-reply-form__selectors {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    .generate-reply-form__selector {
      min-width: 30%;
      legend {
        height: 18px;
        span {
          opacity: 1;
        }
      }

      &.Mui-focused {
        fieldset {
          border-color: #804eea;
        }
      }
    }
  }
}
