.app-thumbnail {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  padding: 10px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-align: center;
  display: flex;
  align-items: center;
  span {
    font-size: 18px;
    font-weight: 500;
    color: #3e3e3e;
    font-family: "Manrope", sans-serif;
  }

  &:hover {
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  }
}
