$font-size-base-px: 16px;
@function rem-calc($px) {
  @return $px / $font-size-base-px * 1rem;
}

.calculator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;

  .calculator-title {
    font-size: rem-calc(56px);
    font-weight: 600;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    color: #070538;
    text-align: center;
    max-width: 80rem;

    @media screen and (max-width: 600px) {
      font-size: rem-calc(40px);
      max-width: none;
    }

    .highlight {
      color: #642ae5;
    }
  }

  .calculator-container {
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);

    max-width: 80rem;
    margin: 100px auto;
    justify-content: center;
    border-radius: 35px;
    border-left: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    padding: 30px;
    font-family: "Manrope", sans-serif;
    background-color: white;
    .calculator-content-wrapper {
      padding: 20px;
      .calculator-content {
        background-color: white;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;
        border-radius: 15px;
        border: 2px solid #6628eb;
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);

        .content-title {
          font-size: rem-calc(18px);
          font-weight: 500;
          line-height: 1.2;
          margin: 0;
          padding: 0;
          color: #3e3e3e;
        }
      }
    }
    .calculator-button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 20px;
      gap: 10px;
      .calculate-button {
        background-color: white;
        border: 2px solid #642ae5;
        max-width: 170px;
        max-height: 50px;
        padding: 10px 20px;
        font-size: 18px;
        &:hover {
          color: #642ae5 !important;
        }
      }
    }

    .calculator-result-wrapper {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .pricing-card {
        border: none;
        background: #642ae5;

        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
        color: white !important;
        .pricing__title {
          color: white !important;
        }
        .pricing__price {
          color: white !important;
        }
        .pricing__text {
          .number-of-replies {
            color: white !important;
          }
          color: white !important;
        }
      }

      .content-result {
        font-size: rem-calc(44px);
        font-weight: 600;
        line-height: 1.2;
        margin: 0;
        padding: 0;

        span {
          color: #642ae5;
        }
      }
    }
  }
}
