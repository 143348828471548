.nav-bar {
  background: white;
  border-radius: 35px;
  margin-top: 15px;
  max-width: 80rem;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  justify-content: space-between;

  .nav-logged-in-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
  }
}

.buttons {
  display: flex;
  margin-top: 20px;
}
