.footer-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(96.5deg, #6628eb -10.56%, #301a5a 113.68%);
  border-radius: 30px;
  gap: 40px;
  padding: 60px 0px;
  max-width: 80rem;
  margin: 60px auto;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .headline {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      font-size: 56px;
      line-height: 64px;
      color: white;
    }
  }

  &.mobile {
    padding: 60px 20px;
    align-items: center;
    justify-content: center;
    .content {
      .headline {
        text-align: center;
        font-size: 35px;
        line-height: 48px;
      }
    }
  }
}
