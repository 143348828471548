.result-container {
  display: flex;
  flex-direction: row;
  .result-textarea {
    border: 1px solid #e6e6e6;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-right: none;
    border-top-right-radius: none;
    border-bottom-right-radius: none;
    padding: 10px;
    font-size: 16px;
    resize: none;
    height: 100px;
    width: 100%;
    outline: none;
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 500;
    background-color: #f7f7f7;
    line-height: 1.8;
    color: #636f7f;
    min-height: 9rem;
  }

  .result-textarea-buttons {
    display: flex;
    flex-direction: column;
    border: 1px solid #e6e6e6;
    background-color: #f7f7f7;

    justify-content: space-evenly;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}
