$font-size-base-px: 16px;
@function rem-calc($px) {
  @return $px / $font-size-base-px * 1rem;
}

.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Manrope";
  font-style: normal;
  margin: 0 auto;
  margin-bottom: 100px;
  max-width: 80rem;

  .contact-form__title {
    font-weight: 600;
    font-size: rem-calc(56px);
    line-height: 64px;
    color: black;
    text-align: center;

    &:nth-child(2) {
      color: #642ae5;
      margin-bottom: 3rem;
    }
  }

  .contact-form {
    background-color: white;
    border-radius: 30px;
    padding: 60px;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .contact-form__user-infos {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 80%;
    }

    .contact-form__input {
      min-width: 300px;
    }

    .contact-form__textarea {
      width: 80%;
      height: 100px;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      padding: 10px;
      font-size: 16px;
      resize: none;
      border: 1px solid #0000003b;
      font-style: normal;
      font-weight: 500;
      line-height: 1.8;
      color: #636f7f;
      min-height: 9rem;

      &:focus {
        border: 2px solid #642ae5 !important;
      }

      &:focus-visible {
        outline: none;
      }
    }
    .contact-form__button {
      min-width: 120px;
      height: 50px;
      border: none;
      color: white;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
    }

    .contact-form__error {
      color: red;
      font-size: rem-calc(14px);
      font-weight: 500;
      line-height: 1.2;
      margin: 0;
      padding: 0;
    }

    .contact-form__success {
      color: green;
      font-size: rem-calc(14px);
      font-weight: 500;
      line-height: 1.2;
      margin: 0;
      padding: 0;
    }
  }

  &.mobile {
    .contact-form__title {
      font-size: rem-calc(32px);
      line-height: 40px;
    }

    .contact-form {
      max-width: 100%;
      min-width: 100%;
      padding: 30px;

      .contact-form__user-infos {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
      }

      .contact-form__input {
        min-width: 100%;
      }

      .contact-form__textarea {
        width: 100%;
      }
    }
  }
}
