.pricing-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background: white;
  border-left: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-radius: 30px;
  padding: 30px;
  gap: 20px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  .price-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    color: #8a96a6;
    .pricing__price {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 64px;
      color: #642ae5;
    }
    .price {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      justify-content: center;
    }
    .full-price {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      color: #8a96a6;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        left: -5%;
        top: 50%;
        right: 0;
        background-color: red;
        width: 110%;
        height: 2px;
        border-color: inherit;
        transform: rotate(-5deg);
      }
    }
  }
  .pricing__title {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #3e3e3e;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #e6e6e6;

    &.no-underline {
      border-bottom: none;
    }
  }
  .pricing__text {
    color: #8a96a6;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: center;

    .number-of-replies {
      font-weight: bold;
      color: #642ae5;
    }
  }

  .purchase-button {
    min-width: 130px;
    margin-top: auto;
  }

  &.custom {
    .pricing__title {
      color: #642ae5;
      border: none;
    }
  }

  &.mobile {
    .pricing-card {
      width: 100%;
      .price-container {
        flex-direction: column;
        align-items: center;
        .pricing__price {
          line-height: 48px;
        }
      }
      .pricing__title {
        line-height: 28px;
      }
      .pricing__text {
        line-height: 20px;
      }
    }
  }
}
