$font-size-base-px: 16px;
@function rem-calc($px) {
  @return $px / $font-size-base-px * 1rem;
}

.pricing-container {
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Manrope, sans-serif;

  .limited-time-offers {
    font-size: rem-calc(56px);
    font-weight: 500;
    color: white;
    position: relative;
    z-index: 102;
    min-width: 80%;
    text-align: center;
    background-color: #642ae5;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0%;
      border-top: 43px solid transparent;
      border-bottom: 43px solid transparent;
      border-left: 48px solid #faf8ff;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0%;
      transform: rotate(180deg);
      border-top: 43px solid transparent;
      border-bottom: 43px solid transparent;
      border-left: 48px solid #faf8ff;
    }
  }
  .pricing-grid {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    max-width: 80rem;
    .pricing-col {
      width: 100%;
      padding: 10px;
    }
    &.mobile {
      flex-direction: column;
      gap: 30px;
      align-content: center;
      padding: 30px 0;
    }
  }

  &.mobile {
    .limited-time-offers {
      font-size: rem-calc(28px);
      line-height: 55px;
      padding: 5px 10px;
      width: 100%;

      &::before {
        border-top: 32px solid transparent;
        border-bottom: 32px solid transparent;
        border-left: 28px solid #faf8ff;
        left: -1px;
      }
      &::after {
        border-top: 32px solid transparent;
        border-bottom: 32px solid transparent;
        border-left: 28px solid #faf8ff;
        right: -1px;
      }
    }
  }
}
