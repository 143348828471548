$font-size-base-px: 16px;
@function rem-calc($px) {
  @return $px / $font-size-base-px * 1rem;
}

.hero-container {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  margin: 30px auto;
  max-width: 80rem;
  border-radius: 35px;
  border-left: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 15px;
  height: 85vh;
  font-family: "Manrope", sans-serif;

  .hero-content {
    gap: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: rem-calc(56px);
      font-weight: 600;
      line-height: 1.2;
      margin: 0;
      padding: 0;
    }

    .headline {
      color: #642ae5;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: rem-calc(18px);
      line-height: 150%;
      color: #8a96a6;

      .highlight {
        color: #642ae5;
        font-weight: 600;
        font-size: rem-calc(20px);
      }
    }
  }

  .hero-tutorial-wrapper {
    min-width: 50%;
    min-height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../images/hero-image.png");
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .tutorial-clone-1 {
      position: absolute;
      top: 22rem;

      @media screen and (min-width: 1400px) {
        top: 23rem;
      }

      @media screen and (min-width: 1600px) {
        top: 24rem;
      }

      @media screen and (min-width: 1800px) {
        top: 26rem;
      }

      width: 70%;
      height: 20%;
      border-radius: 10px;
      background-color: white;
      border: 1px solid #e6e6e6;
      z-index: 3;
    }
    .tutorial-clone-2 {
      position: absolute;
      top: 24rem;

      @media screen and (min-width: 1400px) {
        top: 25rem;
      }

      @media screen and (min-width: 1600px) {
        top: 26rem;
      }

      @media screen and (min-width: 1800px) {
        top: 28rem;
      }
      width: 60%;
      height: 20%;
      border-radius: 10px;
      background-color: white;
      border: 1px solid #e6e6e6;
      z-index: 2;
    }
  }

  &.mobile {
    height: auto;
    .hero-content {
      padding: 30px;
      display: flex;
      flex-direction: column;

      h1 {
        font-size: rem-calc(36px);
      }
      p {
        font-size: rem-calc(14px);
        .highlight {
          font-size: rem-calc(15px);
        }
      }
    }
    .hero-tutorial-wrapper {
      border-radius: 30px;
      padding: 20px;
      .tutorial-clone-1 {
        display: none;
      }
      .tutorial-clone-2 {
        display: none;
      }
    }
  }
}
