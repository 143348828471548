$font-size-base-px: 16px;
@function rem-calc($px) {
  @return $px / $font-size-base-px * 1rem;
}

.works-stepper-container {
  background-color: white;
  padding: 6rem 0rem;
  font-family: "Manrope";
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: center;

  .works-stepper-header {
    font-size: rem-calc(56px);
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    color: #070538;
    margin-bottom: 6rem;

    .highlight {
      color: #642ae5;
    }
  }

  .works-steps-grid {
    max-width: 80rem;
    .works-steps-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .works-step {
        background: #efe6fc;
        border-radius: 24px;
        padding: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        min-height: 130px;
        gap: 20px;
        cursor: pointer;

        .works-step-content {
          h1 {
            color: #1c0846;
            font-weight: 700;
            font-size: rem-calc(24px);
            line-height: rem-calc(32px);
          }
          p {
            font-weight: 500;
            font-size: rem-calc(14px);
            line-height: rem-calc(20px);
            color: #636f7f;
          }
        }
        .works-step-number {
          height: 100%;
          width: 80px;
          background-repeat: no-repeat;
          &.one {
            background-image: url("../../images/number-one-inactive.png");
          }
          &.two {
            background-image: url("../../images/number-two-inactive.png");
          }
          &.three {
            background-image: url("../../images/number-three-inactive.png");
          }
          &.four {
            background-image: url("../../images/number-four-inactive.png");
          }
        }

        &.active {
          background: #642ae5;
          .works-step-content {
            h1 {
              color: white;
            }
            p {
              color: #d4c3f7;
            }
          }

          .works-step-number {
            &.one {
              background-image: url("../../images/number-one-active.png");
            }
            &.two {
              background-image: url("../../images/number-two-active.png");
            }
            &.three {
              background-image: url("../../images/number-three-active.png");
            }
            &.four {
              background-image: url("../../images/number-four-active.png");
            }
          }
        }
      }
    }

    .works-steps-information-container {
      padding: 0 0 0 40px;
      .works-steps-information {
        background: #f5f3fa;
        border-radius: 40px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem 4rem;
        position: relative;
        img {
          width: 100%;
          border-radius: 15px;
          box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  &.mobile {
    .works-steps-grid {
      padding: 0 1rem;
      .works-steps-container {
        flex-direction: row;
        justify-content: space-evenly;
        padding: 0 1rem;
        flex-wrap: wrap;

        .works-step {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 2px;
          max-width: 45%;
          min-width: 45%;
          position: relative;
          .works-step-content {
            display: flex;
            justify-content: center;
            h1 {
              font-size: rem-calc(16px);
              line-height: rem-calc(18px);
              text-align: center;
            }
            p {
              display: none;
            }
          }
          .works-step-number {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 10px;
            right: 5px;
            background-size: contain;
          }
          background-repeat: no-repeat;
          background-position: center;
          background-image: none;
        }
      }
      .works-steps-information-container {
        padding: 0;
        margin-top: 20px;
        .works-steps-information {
          padding: 2rem 1rem;
        }
      }
    }
  }
}
